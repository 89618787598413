import React, { useEffect, useMemo, useRef, useState, type FC, useCallback } from 'react';
import { PropertiesMapProps, PropertyDataProps } from '../../utility/types';
import Map, { Marker, Popup } from 'react-map-gl';
import type { MapRef } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import PropertyQuickViewModal from '../common/modals/PropertyQuickViewModal';
import { Loader, MapIcon_heart } from '../icons/OurIcons';
import '../../../assets/stylesheets/mapbox.css';
import DrawControl from './draw-control';
import * as turf from '@turf/turf';
import useGetProperties from '../../hooks/api/Property/useGetProperties';
import { getMapboxBoundingBox, getMapboxPopupOrientationAndOffset } from '../../utility/utility';
import { useFilteringContext } from '../../contexts/Filtering';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import FreehandPolygonMode from './FreehandPolygon';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

const PropertiesMap: FC<PropertiesMapProps> = ({ listSortId }) => {
  const favorites = useSelector<RootState>(state => state.favorite.favorites);
  const mapKey = useSelector((state: RootState) => state.map.key);

  const mapRef = useCallback((node: MapRef) => {
    if (node !== null) {
      setMapNode(node);
      setMapBoundingBox(getMapboxBoundingBox(node));
    }
  }, []);
  const markersRef = useRef({});
  const { ransackObj, setLassoPolygons } = useFilteringContext();

  const { propertiesData,
    loadingProperties,
    pendingProperties,
    errorProperties } = useGetProperties(listSortId, ransackObj, 500, 1, null);

  const initialProperties: PropertyDataProps[] = propertiesData;
  let latitudes = [];
  let longitudes = [];
  let bounds;

  if (propertiesData) {
    latitudes = propertiesData
      .map(property => property?.address?.latitude ? Number(property.address.latitude) : null)
      .filter(latitude => latitude !== null);

    longitudes = propertiesData
      .map(property => property?.address?.longitude ? Number(property.address.longitude) : null)
      .filter(longitude => longitude !== null);
  }

  if (latitudes.length > 0 && longitudes.length > 0) {
    const sw = new mapboxgl.LngLat(Math.min(...longitudes), Math.min(...latitudes));
    const ne = new mapboxgl.LngLat(Math.max(...longitudes), Math.max(...latitudes));
    bounds = new mapboxgl.LngLatBounds(sw, ne);
  } else {
    // Provide default coordinates if no valid latitudes and longitudes are found
    const defaultLatitude = 0;
    const defaultLongitude = 0;
    const sw = new mapboxgl.LngLat(defaultLongitude, defaultLatitude);
    const ne = new mapboxgl.LngLat(defaultLongitude, defaultLatitude);
    bounds = new mapboxgl.LngLatBounds(sw, ne);
  }

  const [ filteredProperties, setFilteredProperties ] = useState(initialProperties);
  const [ mapBoundingBox, setMapBoundingBox ] = useState(null);
  const [ popupInfo, setPopupInfo ] = useState(null);
  const [ popupOrientation, setPopupOrientation ] = useState(null);
  const [ popupOffset, setPopupOffset ] = useState(0);
  const [ lassoSelections, setLassoSelections ] = useState({});
  const [ mapNode, setMapNode ] = useState(null);
  const [ popupLoading, setPopupLoading ] = useState(false);
  const [ isSatelliteView, setIsSatelliteView ] = useState(false);

  useEffect(() => {
    handleBoundsChange();
  }, [ initialProperties ]);

  useEffect(() => {
    calculateFilteredProperties();

    const lassoPolygons = [];
    for (const selection in lassoSelections) {
      lassoPolygons.push(lassoSelections[selection].geometry);
    }
    setLassoPolygons(lassoPolygons);
  }, [ lassoSelections ]);

  const calculateFilteredProperties = () => {
    if (!mapNode || !propertiesData || !propertiesData.length) {
      return;
    }
    let properties;
    if (Object.keys(lassoSelections).length !== 0) {
      properties = calculatePropertiesInLasso();
    }
    else {
      properties = calculatePropertiesInView(initialProperties);
    }
    setFilteredProperties(properties);
  };

  const toggleSatelliteView = () => {
    const newStyle = isSatelliteView ? 'mapbox://styles/mapbox/streets-v12' : 'mapbox://styles/mapbox/standard-satellite';
    mapNode.getMap().setStyle(newStyle);
    setIsSatelliteView(!isSatelliteView);
  };

  const calculatePropertiesInLasso = () => {
    if (!propertiesData || !propertiesData.length) {
      return;
    }
    if (Object.keys(lassoSelections).length === 0) {
      return calculatePropertiesInView(initialProperties);
    }
    else {

      const lassoCoordinates = [];
      for (const selection in lassoSelections) {
        lassoCoordinates.push(lassoSelections[selection].geometry.coordinates);
      }
      const multiPoly = turf.multiPolygon(lassoCoordinates);

      const propertiesInLasso = initialProperties.filter(property => {
        const point = turf.point([ Number(property.address.longitude), Number(property.address.latitude) ]);
        return turf.booleanPointInPolygon(point, multiPoly);
      });

      return propertiesInLasso;
    }
  };

  const calculatePropertiesInView = (initialProperties) => {
    if (!mapNode || !initialProperties || !initialProperties.length) {
      return [];
    }
    const bounds = mapNode.getMap().getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const topLeft = { lat: ne.lat, lng: sw.lng };
    const bottomRight = { lat: sw.lat, lng: ne.lng };

    const propertiesInView = initialProperties.filter((property) => {
      const propertyLat = Number(property?.address?.latitude ?? 0);
      const propertyLng = Number(property?.address?.longitude ?? 0);

      if (propertyLat === 0 || propertyLng === 0) {
        console.error('Property has invalid information: ', property);
      }

      return (
        propertyLat <= topLeft.lat &&
        propertyLat >= bottomRight.lat &&
        propertyLng >= topLeft.lng &&
        propertyLng <= bottomRight.lng
      );
    });
    return propertiesInView;
  };

  const handleBoundsChange = () => {
    if (!mapNode || !propertiesData || !propertiesData.length) {
      return;
    }

    calculateFilteredProperties();
  };

  const setPopupOrientationAndOffset = (id) => {
    let orientation, offset;
    const customOffset = { top: 10, bottom: -10, left: null, right: null };
    [ orientation, offset ] = getMapboxPopupOrientationAndOffset(markersRef.current[id], mapBoundingBox, 320, customOffset);

    setPopupOrientation(orientation);
    setPopupOffset(offset);
  };

  // Todo: If we have a high enough concentration of addresses in one spot (for example, if we are zoomed out),
  //  it does a larger marker to represent those addresses with a number on the red dot
  const addresses = useMemo(() => {
    if (!filteredProperties || !filteredProperties.length) {
      return [];
    }

    return filteredProperties.flatMap((property) => {
      const { address, id } = property;
      return {
        ...address,
        'propertyId': id,
      };
    });
  }, [ filteredProperties ]);

  // Is an array where each element is the html for a marker on the map
  const markers = useMemo(() => {
    if (!addresses) {
      return [];
    }

    return addresses.map((address) => {
      return (
        <Marker
          longitude={Number(address.longitude ?? 0)}
          latitude={Number(address.latitude ?? 0)}
          key={address.id}
          ref={el => markersRef.current[address.id] = el}
          onClick={e => {
            e.originalEvent.stopPropagation();
            setPopupOrientationAndOffset(address.id);
            setPopupInfo(address);
          }}
        >
          {favorites[address.propertyId] ?
            <div className="cursor-pointer">
              <MapIcon_heart />
              {popupLoading && address === popupInfo && <div className="absolute w-[40px] bottom-[-7px] right-[-7px]">
                <Loader />
              </div>}
            </div>
            : (
              <div className="cursor-pointer w-[12px] h-[12px] bg-rose-500 rounded-full border-solid border-white border-2 drop-shadow">
                {popupLoading && address === popupInfo && <div className='relative bottom-[11px] right-[11px] w-[32px]'><Loader /></div>}
              </div>
            )
          }
        </Marker>);
    });
  }, [ addresses, mapBoundingBox, markersRef, favorites, popupInfo, popupLoading ]);

  const onUpdate = useCallback(e => {
    setLassoSelections(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback(e => {
    setLassoSelections(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  if (errorProperties) {
    return <div id='PropertiesViewApp-error'>Error: {errorProperties.message}</div>;
  }

  if (pendingProperties || loadingProperties) { // make sure this is correct
    return <div id='PropertiesViewApp-loading'>Loading...</div>;
  }
  if (!propertiesData || !propertiesData.length) {
    return <div>No propertiesData</div>;
  }
  return (
    <>
      <div className="h-[800px] w-full relative">
        <div className="absolute z-10 top-2 right-2 rounded-sm bg-neutral-50 text-indigo-600 border-indigo-600 border-2 hover:text-indigo-800 hover:border-indigo-800">
          <button className="py-0.5 px-1" onClick={toggleSatelliteView}>
            {isSatelliteView ? 'Show Standard View' : 'Show Satellite View'}
          </button>
        </div>
        <Map
          ref={mapRef}
          mapboxAccessToken={mapKey}
          initialViewState={{
            bounds: bounds,
            fitBoundsOptions: { padding: 20 }
          }}
          mapStyle="mapbox://styles/mapbox/streets-v12"
          onZoomEnd={handleBoundsChange}
          onDragEnd={handleBoundsChange}
          onRotate={handleBoundsChange}
          onLoad={handleBoundsChange}
        >
          {markers}
          {popupInfo && (
            <Popup
              longitude={popupInfo.longitude}
              latitude={popupInfo.latitude}
              maxWidth="320px"
              anchor={popupOrientation}
              offset={popupOffset}
              closeButton={false}
              closeOnMove={true}
              onClose={() => setPopupInfo(null)}
            >
              <PropertyQuickViewModal
                propertyId={popupInfo.property_id}
                setPopupLoading={setPopupLoading}
              />
            </Popup>
          )}
          <DrawControl
            position='top-left'
            displayControlsDefault={false}
            modes={Object.assign(MapboxDraw.modes, {
              draw_polygon: FreehandPolygonMode
            })}
            controls={{
              polygon: true,
              trash: true
            }}
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        </Map>
      </div>
    </>
  );
};
export default PropertiesMap;
